<template>
    <div class="create_new_inquiry">
        <!-- Personal Information -->
        <validation-observer ref="personalInfoForm">
        <b-card
            title="Personal Information"
        >

            <!-- form -->
            <b-form>
                <b-row class="personal_information_wrapper">
                    <!-- image -->
                    <b-col md="1" :class="{'afterUploadImg':previewImage.length!=0}">
                        <validation-provider
                            #default="{ errors }"
                            name="image file"                           
                        >
                            <img v-if="previewImage.length!=0" :src="previewImage" alt="profile image" width="160px" height="145px" class="profile_img">
                            <b-form-file
                                id="imagePicker"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                accept="image/*"
                                @change="checkFile"
                                :state="errors.length > 0 ? false:null"
                            />
                             <img src='@/assets/images/icons/onedrive.png' class="upload_img_inner">
                             <b-spinner variant="primary" v-if="uploadingImage" large style="position: absolute;z-index:999; top:50px;left:40%"/>
                            <input type="text" v-model="previewImage" v-show="false">
                            <small class="text-danger">{{ errors[0] }}</small>
                            <!-- <small class="text-danger" v-if="isImage">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Please select an image file.</small> -->
                        </validation-provider>
                    </b-col>
                    <b-col md="11">
                        <b-row>
                            <!-- Name -->
                            <b-col md="3">
                                <b-form-group label="First Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="first name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="firstName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                             <b-col md="3">
                                <b-form-group label="Last Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="last name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="lastName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Email -->
                            <b-col md="3">
                                <b-form-group label="Email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        v-model="email"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="email=email.toLowerCase().trim()"
                                    />
                                    <small class="text-danger" v-if="!checkEmail">{{ errors[0] }}</small>
                                    <small class="text-danger" v-else>This email is already user.</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Phone -->
                            <b-col md="3">
                                <b-form-group label="Mobile Number" class="position-relative cls_phoneno_wrapper">
                                    <span class="phoneno_prefix"><phoneCountryCodeVue @country-selected="onCountrySelected"/></span>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="mobile no."
                                        rules="required|integer"
                                    >
                                        <b-form-input
                                            v-model="mobileNumber"
                                            name="mobile no."
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter number"
                                            @blur="mobileNumber=mobileNumber.trim()"
                                            @keypress="isOnlyInteger"
                                            @input="checkValidPhoneNumber"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="errors.length == 0 && !validNumberOrNot">Please provide valid phone number.</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Date -->
                            <b-col md="3">
                                <b-form-group label="Date of Birth">
                                <validation-provider
                                    #default="{ errors }"
                                    name="date"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="example-input"
                                        v-model="dob"
                                        type="date"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                        @change="changeBirthDate()"
                                        :state="errors.length > 0 ? false:null"
                                        :max="getMaxDate()"
                                        class="input-date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Gender -->
                            <b-col md="3">
                                <b-form-group label="Gender">
                                <validation-provider
                                    #default="{ errors }"
                                    name="gender"
                                    rules="required"
                                >
                                <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                                <!-- <div> -->
                                    <b-form-radio
                                        v-model="gender"
                                        plain
                                        name="gender"
                                        value="Male"
                                    >
                                        Male
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="gender"
                                        plain
                                        name="gender"
                                        value="Female"
                                    >
                                        Female
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="gender"
                                        plain
                                        name="gender"
                                        value="Other"
                                    >
                                        Other
                                    </b-form-radio>
                                </div>
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Select Representative -->
                            <b-col md="3">
                                <b-form-group label="Representative">
                                <validation-provider
                                    #default="{ errors }"
                                    name="representative"
                                    rules=""
                                >
                                    <b-form-select
                                        v-model="representative"
                                        :options="representativeOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <!-- Select Document Module -->
                            <b-col md="3">
                                <b-form-group label="Select Document Module">
                                <validation-provider
                                    #default="{ errors }"
                                    name="select document module"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="documentModule"
                                        label="title"
                                        multiple
                                        :options="documentModuleOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- intake -->
                            <b-col md="3">
                                <b-form-group label="Intake">
                                <validation-provider
                                    #default="{ errors }"
                                    name="intake"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="intake"
                                        :options="intakeOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Year -->
                            <b-col md="3">
                                <b-form-group label="Year">
                                <validation-provider
                                    #default="{ errors }"
                                    name="year"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="year"
                                        :options="yearOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <b-row class="match-height">
            <!-- Communication Address -->
            <b-col md="12">
                <b-card
                    title="Communication Address"
                >
                    <!-- form -->
                    <b-form>
                        <b-row>
                            <!-- Address Line 1 -->
                            <b-col md="3">
                                <b-form-group label="Address Line 1">
                                <validation-provider
                                    #default="{ errors }"
                                    name="address line 1"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="addressLine1"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="addressLine1=addressLine1.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Address Line 2 -->
                            <b-col md="3">
                                <b-form-group label="Address Line 2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="address line 2"
                                    rules=""
                                >
                                    <b-form-input
                                        v-model="addressLine2"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="addressLine2=addressLine2.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Country -->
                            <b-col md="3">
                                <b-form-group label="Country">
                                <validation-provider
                                    #default="{ errors }"
                                    name="country"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="country"
                                        :options="countryOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                        @change="getStates()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- State -->
                            <b-col md="3">
                                <b-form-group label="State">
                                <validation-provider
                                    #default="{ errors }"
                                    name="state"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="state"
                                        :disabled="!country"
                                        :options="stateOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                        @change="getCities()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- City -->
                            <b-col md="3">
                                <b-form-group label="City">
                                <validation-provider
                                    #default="{ errors }"
                                    name="city"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="city"
                                        :disabled="!state"
                                        :options="cityOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Pincode -->
                            <b-col md="3">
                                <b-form-group label="Pincode">
                                <validation-provider
                                    #default="{ errors }"
                                    name="pincode"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="pincode"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Pincode"
                                        @blur="pincode=pincode.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
            <!-- Educational Details -->
            <b-col md="12">
                <div
                    class="card"
                >
                    <!-- form -->
                    <div class="card-body edit_student_main">
                        <h4 class="card-title">
                            Education Details
                        </h4>
                        <b-form>
                            <b-row v-for="(data,index) in educationDetails" :key="'educationDetails'+index" :style="index!=educationDetails.length-1?'border-bottom:1px solid #cfcfcf;':''">
                                <!-- Education -->
                                <b-col md="3" v-if="index<2">
                                    <b-form-group label="Education">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="education"
                                        :vid="'education'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.education"
                                            :disabled="true"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.education=data.education.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!-- ADD BRANCH AMD DEGREE FIELD WHEN EDUCATION IS BACHELOR START-->
                                <b-col md="3" v-if="index>=2">
                                    <b-form-group label="Degree">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="degree"
                                        :vid="'degree'+index"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.edId"
                                            :options="educationOptions"
                                            :state="errors.length > 0 ? false:null"
                                            @change="(e)=>{educationFiledSet(e,index,'edId','education',1)}"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col> 
                                <b-col md="3" v-if="index>=2">
                                    <b-form-group label="Branch/Stream/Field">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="branch/stream/field"
                                        :vid="'branch/stream/field'+index"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.bsfId"
                                            :options="branchFieldOptions"
                                            :state="errors.length > 0 ? false:null"
                                            @change="(e)=>{educationFiledSet(e,index,'bsfId','branchOrStreamOrFieldName',4)}"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger" v-if="data.bsfId !== 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="branch/stream/field"
                                        :vid="'branch/stream/field1'+index"
                                        :rules="data.bsfId == 'others'?'required':''"
                                    >
                                        <b-form-input
                                            style="margin-top: 5px;"
                                            v-if="data.bsfId == 'others'"
                                            v-model="data.branchOrStreamOrFieldName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.branchOrStreamOrFieldName=data.branchOrStreamOrFieldName.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col> 

                                <!-- School/College Name -->
                                <b-col md="3"  v-if="index<2">
                                    <b-form-group label="School/ College Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="school/college name"
                                        :vid="'school/college name'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.schoolOrCollegeName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.schoolOrCollegeName=data.schoolOrCollegeName.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col> 
                                <b-col md="3" v-else>
                                    <b-form-group label="School/ College Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="school/college name"
                                        :vid="'school/college name1'+index"
                                        :rules="'required'"
                                    >
                                        <b-form-select
                                            v-model="data.scId"
                                            :options="schoolOrCollegeNameOptions"
                                            :state="errors.length > 0 ? false:null"
                                            @change="(e)=>{educationFiledSet(e,index,'scId','schoolOrCollegeName',2)}"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger" v-if="data.scId !== 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="school/college name"
                                        :vid="'school/college name2'+index"
                                        :rules="data.scId == 'others'?'required':''"
                                    >
                                        <b-form-input
                                            style="margin-top: 5px;"
                                            v-if="data.scId == 'others'"
                                            v-model="data.schoolOrCollegeName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.schoolOrCollegeName=data.schoolOrCollegeName.trim()"
                                        />
                                        <small class="text-danger" v-if="data.scId == 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- Board/University Name -->
                                <b-col md="3" v-if="index<2">
                                    <b-form-group label="Board/ University Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="board/university name"
                                        :vid="'board/university name'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.boardUniversityName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.boardUniversityName=data.boardUniversityName.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-else>
                                    <b-form-group label="Board/ University Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="board/university name"
                                        :vid="'board/university name'+index"
                                        :rules="'required'"
                                    >
                                        <b-form-select
                                            v-model="data.bouId"
                                            :options="boardUniversityNameOptions"
                                            @change="(e)=>{educationFiledSet(e,index,'bouId','boardUniversityName',3)}"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger" v-if="data.bouId !== 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="board/university name"
                                        :vid="'board/university name'+index"
                                        :rules="data.bouId == 'others'?'required':''"
                                    >
                                        <b-form-input
                                            v-if="data.bouId == 'others'"
                                            style="margin-top: 5px;"
                                            v-model="data.boardUniversityName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.boardUniversityName=data.boardUniversityName.trim()"
                                        />
                                        <small class="text-danger" v-if="data.bouId == 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- CGPA/PERCENTAGE -->
                                <b-col md="3">
                                    <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="cgpi/percentage"
                                        :vid="'cgpi/percentage'+index"
                                        rules="required"
                                    >
                                    <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;margin-top:30px">
                                    <!-- <div> -->
                                        <b-form-radio
                                            v-model="data.cgpa_percentage"
                                            plain
                                            value="Percentage"
                                        >
                                            Percentage
                                        </b-form-radio>
                                        <b-form-radio
                                            v-model="data.cgpa_percentage"
                                            plain
                                            value="CGPI"
                                        >
                                            CGPI
                                        </b-form-radio>
                                        <b-form-radio
                                            v-model="data.cgpa_percentage"
                                            plain
                                            value="Grade"
                                        >
                                            Grade
                                        </b-form-radio>
                                    </div>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- CGPA -->
                                <b-col md="3" v-if="data.cgpa_percentage!='Grade'">
                                    <b-form-group :label="data.cgpa_percentage">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="data.cgpa_percentage"
                                        :vid="data.cgpa_percentage+index"
                                        :rules="data.cgpa_percentage!='CGPI'?'required|max_value:100':'required|max_value:10'"
                                    >
                                        <b-form-input
                                            v-model="data.cgpa_percentageValue"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Number"
                                            @blur="data.cgpa_percentageValue=data.cgpa_percentageValue.trim()"
                                            @keypress="isOnlyDecimal"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!-- CGPA -->
                                <b-col md="3" v-else>
                                    <b-form-group :label="data.cgpa_percentage">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="data.cgpa_percentage"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.cgpa_percentageValue"
                                            :options="gradeOptions"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- Year of passing -->
                                <b-col md="3">
                                    <b-form-group label="Year of Passing">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="year of passing"
                                        :vid="'year of passing'+index"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.yearOfPassing"
                                            :options="passingYearOptions"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col style="text-align: right">
                                    <b-button variant="outline-danger" v-if="index>1" @click.prevent="removeEducationRow(index)">
                                        Remove Education
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                    <div class="card-body add_edu_link" style="border-top:1px solid #cfcfcf;text-decoration:underline;color:#3b3bf6;font-weight:600;">
                        <a @click.prevent="addNewEducationRow()">Add a Education</a>
                    </div>
                </div>
            </b-col>
            <b-col>
                <b-row>
                    <!-- Languages -->
                    <b-col md="12">
                        <b-card
                            title="Language"
                        >
                            <!-- form -->
                            <b-form>
                                <b-row>
                                    <!-- Select Language -->
                                    <!-- <b-col md="6" class="pb-0">
                                        <b-form-group label="Language">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select representative"
                                            rules="required"
                                        >
                                            <b-form-select
                                                v-model="language"
                                                :options="languageOptions"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col> -->
                                    <!-- German Language -->
                                    <b-col md="6">
                                        <b-form-group label="German Language">
                                        <!-- <b-form-group> -->
                                        <validation-provider
                                            #default="{ errors }"
                                            name="german language"
                                            rules="required"
                                        >
                                        <div style="display:flex;justify-content:space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                                            <b-form-radio
                                                v-model="germanLanguage"
                                                plain
                                                name="german language"
                                                value="None"
                                            >
                                                None
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="germanLanguage"
                                                plain
                                                name="german language"
                                                value="A1"
                                            >
                                                A1
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="germanLanguage"
                                                plain
                                                name="german language"
                                                value="A2"
                                            >
                                                A2
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="germanLanguage"
                                                plain
                                                name="german language"
                                                value="B1"
                                            >
                                                B1
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="germanLanguage"
                                                plain
                                                name="german language"
                                                value="B2"
                                            >
                                                B2
                                            </b-form-radio>
                                        </div>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>
                    </b-col>
                    <!-- Exams -->
                    <b-col md="12">
                        <b-card
                            title="Exams"
                        >
                            <!-- form -->
                            <b-form>
                                <b-row>
                                    <!-- Exams -->
                                    <b-col md="6">
                                        <b-form-group label="Exams">
                                        <validation-provider
                                        #default="{ errors }"
                                        name="exams"                                       
                                        >
                                        <v-select
                                            v-model="exams"
                                            label="title"
                                            multiple
                                            :options="examOptions"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Band Scores -->
                                    <b-col md="6" v-for="(data,index) in exams" :key="'band scores'+index">
                                        <b-form-group :label="data.title">
                                        <validation-provider
                                        #default="{ errors }"
                                        :name="data.title"
                                        rules="required"
                                        >
                                        <b-form-input
                                            v-model="data.score"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Number"
                                            @blur="data.score=data.score.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
            <!-- Field of Interest -->
            <b-col md="6">
                <b-row>
                    <b-col md="12">
                        <b-card title="Student Interest">
                            <b-form-group label="Area of Interest">
                                <b-form-input
                                    v-model="areaOfInterest"
                                    placeholder="Enter Text"
                                    @blur="areaOfInterest=areaOfInterest.trim()"
                                />
                            </b-form-group>
                        </b-card>
                    </b-col>

                    <!-- Field Of Interest Hidden -->
                    <b-col md="12" v-if="false">
                        <b-card
                            title="Field of Interest"
                        >
                            <!-- form -->
                            <b-form>
                                <b-row v-for="(data,index) in fieldOfInterests" :key="'field of interest'+index" :style="fieldOfInterests.length>0?'border-bottom:1px solid #cfcfcf':''" style="margin-top:10px">
                                    <!-- Select Field -->
                                    <b-col md="6">
                                        <b-form-group label="Select Field">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select field"
                                            rules="required"
                                            :vid="'select field'+index"
                                        >
                                            <b-form-select
                                                v-model="data.field"                                                                              
                                                :options="fieldOptions"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                                @input="filterCourse(index)"
                                            />                                  
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Select Course -->
                                    <b-col md="6">
                                        <b-form-group label="Select Course">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select course"
                                            rules="required"
                                            :vid="'select course'+index"
                                        >
                                            <b-form-select
                                                v-model="data.course"
                                                :disabled="!data.field"
                                                :options="data.courseOption"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <div  class="add_edu_link" style="font-weight:600;margin-bottom:10px;width:100%;display:flex;justify-content:space-between;padding:0px 14px">
                                        <a v-if="index==fieldOfInterests.length-1" style="text-decoration:underline;color:#3b3bf6;" @click.prevent="addNewRow()">
                                            Add New Row
                                        </a>
                                        <a v-if="index!=0" class="add_edu_link_remove" style="text-decoration:underline;color:#ff0b0b" :style="index!=fieldOfInterests.length-1?'text-align: right;display: block;width: 100%;':''" @click.prevent="removeRow(index)">
                                                Remove
                                        </a>
                                    </div>
                                </b-row>
                                <div class="add_edu_link" style="font-weight:600;margin-bottom:10px;width:100%;display:flex;justify-content:space-between;padding:0px 14px">
                                    <a v-if="fieldOfInterests.length==0" @click.prevent="addNewRow()">
                                        Add New Row
                                    </a>
                                </div>
                            </b-form>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>            
            <!-- Payment -->
            <b-col md="6" v-if="false">
                <b-card
                    title="Payment"
                >
                    <!-- form -->
                    <b-form>
                        <b-row v-for="(data,index) in payments" :key="'payments'+index">
                            <!-- Date -->
                            <b-col md="6">
                                <b-form-group label="Payment Received Date">
                                <validation-provider
                                    #default="{ errors }"
                                    name="payment received date"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="example-input"
                                        v-model="data.paymentReceivedDate"
                                        onkeydown="return false"
                                        type="date"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                        :state="errors.length > 0 ? false:null"
                                        :max="getMaxDate()"
                                         class="input-date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Mode Of Payment -->
                            <b-col md="6">
                                <b-form-group label="Mode Of Payment">
                                <validation-provider
                                    #default="{ errors }"
                                    name="mode of payment"
                                    rules="required"
                                >
                                <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                                <!-- <div> -->
                                    <b-form-radio
                                        v-model="data.modeOfPayment"
                                        plain
                                        name="mode of payment"
                                        value="Cash"
                                    >
                                        Cash
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="data.modeOfPayment"
                                        plain
                                        name="mode of payment"
                                        value="Cheque"
                                    >
                                        Cheque
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="data.modeOfPayment"
                                        plain
                                        name="mode of payment"
                                        value="Other"
                                    >
                                        Other
                                    </b-form-radio>
                                </div>
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Bank Name -->
                            <b-col md="6" v-if="data.modeOfPayment == 'Cheque'">
                                <b-form-group label="Bank Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="bank name"
                                    rules="required|alpha_spaces"
                                >
                                    <b-form-input
                                        v-model="data.bankName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="data.bankName=data.bankName.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Cheque No. -->
                            <b-col md="6"  v-if="data.modeOfPayment == 'Cheque'">
                                <b-form-group label="Cheque No.">
                                <validation-provider
                                    #default="{ errors }"
                                    name="cheque no."
                                    rules="required|integer|min:6|max:6"
                                >
                                    <b-form-input
                                        v-model="data.chequeNo"
                                        name="cheque no."
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter number"
                                        @blur="data.chequeNo=data.chequeNo.trim()"
                                        @keypress="isOnlyInteger"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Rupees (INR) -->
                            <b-col md="6">
                                <b-form-group label="Rupees (INR)">
                                <validation-provider
                                    #default="{ errors }"
                                    name="rupees"
                                    rules="required|min_value:1"
                                >
                                    <b-form-input
                                        v-model="data.rupees"
                                        name="rupees"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="0.00"
                                        @blur="data.rupees=data.rupees.trim()"
                                        @keypress="isOnlyDecimal"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Remark -->
                            <b-col md="12">
                                <b-form-group label="Remark" v-if="data.modeOfPayment=='Other'">
                                <validation-provider
                                    #default="{ errors }"
                                    name="remark"
                                    rules=""
                                >
                                    <b-form-textarea
                                        v-model="data.remark"
                                        name="remark"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="data.remark=data.remark.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>

        </validation-observer>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,
  BFormTextarea,BSpinner
} from 'bootstrap-vue'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,alpha,double,between } from 'vee-validate/dist/rules';
import phoneCountryCodeVue from '@/views/component/phoneCountryCode.vue';
import { Country, State, City }  from 'country-state-city';
import createPhoneNumberRegex from '@/utils/makeRegexForVal.js';

extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('alpha',alpha)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)
extend('between',between)
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dbCollections } from "@/utils/firebaseCollection.js";
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import { ValidationFunction } from '@/utils/globalValidations.js';
import {updateFiledCollectionWithStudentID} from '@/utils/addRemoveAssignStudentFromFiels.js'
import {CountriesLength} from "@/utils/countries.js"

import vSelect from 'vue-select'

const db = firebase.firestore()

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardText,
        BSpinner,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        vSelect,
        phoneCountryCodeVue
    },
    data() {
        return {
             CountriesLength,
            uploadingImage:false,
            allEmails:[],
            intakeOptions: [
                { value: null, text: 'Select' },
                { value: 'WS', text: 'WS' },
                { value: 'SS', text: 'SS' },
            ],
            gradeOptions:[
                {value: '', text: 'Select'},
                {value: 'A+', text: 'A+'},
                {value: 'A', text: 'A'},
                {value: 'B+', text: 'B+'},
                {value: 'B', text: 'B'},
                {value: 'C+', text: 'C+'},
                {value: 'C', text: 'C'},
                {value: 'D+', text: 'D+'},
                {value: 'D', text: 'D'},
                {value: 'F', text: 'F'},
            ],
            countryOptions: [{value:null,text:'Select'}],
            stateOptions: [{value:null,text:'Select'}],
            cityOptions: [{value:null,text:'Select'}],
            representativeOptions: [{value:null,text:'Select',disabled:true}],
            documentModuleOptions: [],
            isImage:true,
            imgFile:[],
            imageFile:[],
            previewImage:[],
            name: '',
            firstName:'',
            lastName:'',
            intake: null,
            passingYearOptions:[],
            email:"",
            mobileNumber:"",
            dob:"",
            gender:"Male",
            representative:null,
            documentModule:null,

            addressLine1:"",
            addressLine2:"",
            country:null,
            state:null,
            city:null,
            pincode:"",

            // educationDetails:[{
            //     education:"",
            //     schoolOrCollegeName:'',
            //     boardUniversityName:"",
            //     cgpa_percentage:"Percentage",
            //     cgpa_percentageValue:"",
            //     yearOfPassing:null,
            // }],
            educationDetails:[
                {
                    education:"10th/SSC",
                    schoolOrCollegeName:'',
                    boardUniversityName:"",
                    cgpa_percentage:"Percentage",
                    key:"10thssc",
                    cgpa_percentageValue:"",
                    yearOfPassing:null,
                },
                {
                    education:"12th/HSC/Diploma",
                    schoolOrCollegeName:'',
                    boardUniversityName:"",
                    key:"12thhscdiploma",
                    cgpa_percentage:"Percentage",
                    cgpa_percentageValue:"",
                    yearOfPassing:null,
                }
            ],

            exams:[],
            examOptions:[
                {title:'GRE',score:''},
                {title:'DUOLINGO',score:''},
                {title:'IELTS',score:''},
                {title:'GMAT',score:''},
                {title:'TOEFL',score:''},
                {title:'MCAT',score:''}
            ],
            language:null,
            languageOptions:[{value:null,text:'Select'},'English','Gujarati','Sanskrit','Hindi'],
            germanLanguage:"None",

            fieldOfInterests:[{
                field:null,
                course:null,
                courseOptions:[{ value: null, text: 'Select' }],
            }],

            field:null,
            fieldOptions:[
                { value: null, text: 'Select' },
            ],
            course:null,
            allCourse:[],
            courseOptions:[
                { value: null, text: 'Select' },
            ],

            //Firebase storage Folder name to store files
            folderName:'Student Images',

            'areaOfInterest':'',

            payments:[{
                paymentReceivedDate:'',
                modeOfPayment: '',
                bankName: '',
                chequeNo:'',
                remark:'',
                rupees: ''
            }],

            year:null,
            yearOptions:[{value:null,text:'Select'}],
            todayDate:new Date(),
            educationOptions:[],
            schoolOrCollegeNameOptions:[],
            boardUniversityNameOptions:[],
            branchFieldOptions:[],
            snapshotListener:null,
            snapshotListener1:null,
            snapshotListener2:null,
            snapshotListener3:null,
            documentList:[],
            documentList1:[],
            documentList2:[],
            documentList3:[],
            selectedCountry: null,
            validNumberOrNot: true,
        }
    },
    beforeDestroy() {
        if(this.snapshotListener !== null)
        {
            this.snapshotListener()
        }
        if(this.snapshotListener1 !== null)
        {
            this.snapshotListener1()
        }
        if(this.snapshotListener2 !== null)
        {
            this.snapshotListener2()
        }
        if(this.snapshotListener3 !== null)
        {
            this.snapshotListener3()
        }
    },
    created() {
        var self = this

        var countries = Country.getAllCountries()
        self.countryOptions=[{value:null,text:'Select'}]
        countries.forEach(data=>{
            self.countryOptions.push({value:data,text:data.name})
        })
        self.getOptionsOfUniversity();
        self.getOptionsOfSchoolColleges();
        self.getOptionsOfEducation();
        self.getOptionsOfBranchFiels();

        //push years
        for (let i = 0; i <= 4; i++)
        {
            self.yearOptions.push(new Date().getFullYear()+i)
        }
        // console.log("Co",self.countryOptions,countries)

        var year = new Date().getFullYear()
        self.passingYearOptions.push({value:null, text:"select"})
        self.educationOptions.push({value:'', text:"select"})
        self.branchFieldOptions.push({value:'', text:"select"})
        self.schoolOrCollegeNameOptions.push({value:'', text:"select"})
        self.boardUniversityNameOptions.push({value:'', text:"select"})
        for (let index = year+3; index >= year-30; index--) {
            self.passingYearOptions.push({value:index, text:index})
        }
        self.$root.$on('saveNewStudent',()=>{
            if(self.$refs.personalInfoForm)
            {
                self.saveStudentData()
            }
        })
        self.$root.$on('closeInquiry',()=>{
            if(self.$refs.personalInfoForm)
            {
                self.$refs.personalInfoForm.reset()
                self.$router.push({name:'student-inquiry'})
            }
        })

        //get Representatives
        db
            .collection(dbCollections.USERS)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                let representativeArray = [];
                let employeeArry = [];
                querySnapshot.forEach(doc=>{
                    if(!doc.data().isDeleted && doc.data().role == 'employee') {
                        if(doc.data().representative){
                        employeeArry.push({id:doc.data().id,rId:doc.data().representative.id})
                        } else {
                        employeeArry.push({id:doc.data().id})
                        }
                    }
                })
                function findRelatedRIds(id) {
                    const relatedRIds = [];
                    relatedRIds.push(id)
                    const visitedIds = new Set();

                    function traverseRelations(currentId) {
                        visitedIds.add(currentId);
                        
                        for (const relation of employeeArry) {
                            if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                                relatedRIds.push(relation.id);
                                traverseRelations(relation.id);
                            }
                        }
                    }
                    
                    traverseRelations(id);
                    
                    return relatedRIds;
                }
                let id = firebase.auth().currentUser.uid
                representativeArray = findRelatedRIds(id)
                // employeeArry.forEach((item)=>{
                //     if(representativeArray.length === 0) {
                //         if(item.id == firebase.auth().currentUser.uid){
                //             representativeArray.push(firebase.auth().currentUser.uid)
                //         }
                //         if(item.rId){
                //             if(item.rId == firebase.auth().currentUser.uid){
                //                 representativeArray.push(item.id)
                //             }
                //         }
                //     } else {
                //         if(item.rId){
                //             if(representativeArray.includes(item.rId)){
                //                 representativeArray.push(item.id)
                //             }
                //         }
                //     }
                // })
                querySnapshot.forEach((doc)=>{
                    if(representativeArray.includes(doc.data().id) && !doc.data().isDeleted)
                    {
                        self.representativeOptions.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id,phoneNo:doc.data().personalPhoneNo},text:doc.data().name})
                    }
                })
            })
            .catch(error=>console.log(error))

        //Get Document Modules
        db
            .collection(dbCollections.DOCUMENTCATEGORY)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                querySnapshot.forEach(doc=>{
                    if(!doc.data().isDeleted)
                    {
                        self.documentModuleOptions.push({title:doc.data().categoryName,id:doc.data().id})
                    }
                })
            })
            .catch(error=>{
                console.log(error)
            })

        // //Get Fields
        // db
        //     .collection(dbCollections.FIELDS)
        //     .orderBy('createdAt')
        //     .get()
        //     .then((querySnapshot)=>{
        //         querySnapshot.forEach(doc=>{
        //             self.fieldOptions.push({text:doc.data().fieldName,value:{name:doc.data().fieldName,id:doc.data().id}})
        //         })
        //     })
        //     .catch(error=>{
        //         console.log(error)
        //     })

        // //Get Course
        // db
        //     .collection(dbCollections.COURSES)
        //     .orderBy('createdAt')
        //     .get()
        //     .then((querySnapshot)=>{
        //         querySnapshot.forEach(doc=>{
        //             self.allCourse.push({text:doc.data().courseName,value:{name:doc.data().courseName,id:doc.data().id,fieldId:doc.data().fieldData.id}})
        //         })
        //     })
        //     .catch(error=>{
        //         console.log(error)
        //     })

        //Get Fields And Course
        db
        .collection(dbCollections.FIELDANDCOURSE)
        .orderBy('createdAt')
        .get()
        .then((querySnapshot)=>{
            querySnapshot.forEach(doc=>{
            if(!doc.data().isDeleted)
            {
                self.fieldOptions.push({text:doc.data().fieldName,value:{name:doc.data().fieldName,id:doc.data().id}})            
                // doc.data().courses.forEach(data=>{
                self.allCourse.push({text:doc.data().fieldName,id:doc.data().id,course:[]})
                doc.data().courses.forEach(val=>{
                    self.allCourse[self.allCourse.length-1].course.push(val.name)
                })
                //   self.courseOptions.push({title:data,value:{name:data,id:doc.data().id}})
                // })
            }
            })
        })

        //Get user Emails
        db
            .collection(dbCollections.USERS)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                querySnapshot.forEach(doc=>{
                    // self.allEmails.push(doc.data().email)
                    if(doc.data().role.toLowerCase() == 'student')
                    {
                        self.allEmails.push(doc.data().email)
                    } else if(doc.data().role.toLowerCase() == 'employee') {
                        self.allEmails.push(doc.data().companyEmail)
                    }
                })
            })
            .catch(error=>{
                console.log(error)
            })
    },
    methods: {
        checkValidPhoneNumber(event) {
            if (this.selectedCountry !== null) {
                let regexPattern;
                const lengths = this.CountriesLength.find((e)=>e.alpha2 === this.selectedCountry.code)?.phone_number_lengths || [10,15]
                regexPattern = createPhoneNumberRegex(lengths)
                this.validNumberOrNot = regexPattern.test(event);
            }

            return this.validNumberOrNot;
        },
        onCountrySelected(country) {
            this.selectedCountry = country;
        },
        educationFiledSet(e,index,fieldId,fieldName,type) {
            var self = this
            if(self.educationOptions.length>0 && type == 1) {
                if(e) {
                    let fIndex = self.educationOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.educationDetails[index][fieldId] = self.educationOptions[fIndex].value
                        self.educationDetails[index][fieldName] = self.educationOptions[fIndex].text
                    }
                }
            }
            if(self.schoolOrCollegeNameOptions && type == 2) {
                if(e) {
                    let fIndex = self.schoolOrCollegeNameOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.educationDetails[index][fieldId] = self.schoolOrCollegeNameOptions[fIndex].value
                        if(self.educationDetails[index]['scId'] == 'others') {
                            self.educationDetails[index][fieldName] = ''
                            self.educationDetails[index]['schoolOrCollegeName'] = ''
                            self.educationDetails[index][fieldId] = 'others'
                        } else {
                            self.educationDetails[index][fieldName] = self.schoolOrCollegeNameOptions[fIndex].text
                        }
                    }
                }
            }
            if(self.boardUniversityNameOptions && type == 3) {
                if(e) {
                    let fIndex = self.boardUniversityNameOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.educationDetails[index][fieldId] = self.boardUniversityNameOptions[fIndex].value
                        if(self.educationDetails[index]['bouId'] == 'others') {
                            self.educationDetails[index][fieldName] = ''
                            self.educationDetails[index]['boardUniversityName'] = ''
                            self.educationDetails[index][fieldId] = 'others'
                        } else {
                            self.educationDetails[index][fieldName] = self.boardUniversityNameOptions[fIndex].text
                        }
                    }
                }
            }
            if(self.branchFieldOptions.length>0 && type == 4) {
                if(e) {
                    let fIndex = self.branchFieldOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.educationDetails[index][fieldId] = self.branchFieldOptions[fIndex].value
                        // self.educationDetails[index][fieldName] = self.branchFieldOptions[fIndex].text
                        if(self.educationDetails[index]['bsfId'] == 'others') {
                            self.educationDetails[index][fieldName] = ''
                            self.educationDetails[index]['branchOrStreamOrFieldName'] = ''
                            self.educationDetails[index][fieldId] = 'others'
                        } else {
                            self.educationDetails[index][fieldName] = self.branchFieldOptions[fIndex].text
                        }
                    }
                }
            }
        },
        addZero(val)
        {
            if(val<10)
            {
                return `0`+val
            }
            else
            {
                return val
            }
        },
        getMaxDate()
        {
            return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
        },
        isOnlyDecimal(event) {
            ValidationFunction.OnlyFloatWithNumber(event);
        },
        isOnlyInteger(event) {
            ValidationFunction.OnlyIntegerNumber(event);
        },

        getStates()
        {
            var self = this

            self.state=null
            self.city=null
            
            self.stateOptions=[{value:null,text:'Select'}]
            var states = State.getStatesOfCountry(self.country.isoCode)
            states.forEach(data=>{
                self.stateOptions.push({value:data,text:data.name})
            })
        },
        getCities()
        {
            var self = this

            self.city=null
            
            self.cityOptions=[{value:null,text:'Select'}]
            var cities = City.getCitiesOfState(self.country.isoCode,self.state.isoCode)
            cities.forEach(data=>{
                self.cityOptions.push({value:data,text:data.name})
            })
        },
        filterCourse(index)
        {
            // var self = this

            // self.courseOptions=[{value:null,text:'Select'}]
            // self.allCourse.forEach(data=>{
            //     if(data.value.fieldId==self.fieldOfInterests[index].field.id)
            //     {
            //         self.courseOptions.push(data)
            //     }
            // })
            var self = this
            if(self.fieldOfInterests[index].field!=null)
            {
                self.allCourse.forEach(data=>{                 
                    if(data.id==self.fieldOfInterests[index].field.id)
                    {
                        if(self.fieldOfInterests[index].courseOption == undefined){
                            self.fieldOfInterests[index].courseOption = [];
                        }                        
                        self.fieldOfInterests[index].courseOption = data.course
                    }
                })
            }
        },
        //Check if is valid(image) file
        checkFile(e)
        {
            var files = e.target.files || e.dataTransfer.files;
            this.isImage=true
            if(files.length!=0)
            {
                this.previewImage=[]
                this.imageFile=[]
            }
            // this.attachmentImages=[]
            // this.attachments=[]
            files.forEach(data=>{
                if(data.type.split('/')[0]!='image')
                {
                    this.isImage=false
                    // this.attachmentImages=[]
                }
            })
            if(this.isImage){
                this.isImage=true
                this.createImage(files)
                // console.log(this.attachments)
            }
            else
            { 
                // document.getElementById("imagePicker").value=""
            }
        },
        createImage(files) {
            //   var image = new Image();
            files.forEach(data=>{
                var vm = this;
                var reader = new FileReader();
    
                reader.onload = (e) => {
                    vm.previewImage.push(e.target.result);
                    vm.imageFile.push(data)
                    // console.log("images")
                    // console.log(vm.previewImage)
                    // console.log(vm.imageFile)
                }
                reader.readAsDataURL(data);
            })
        },
        addNewEducationRow()
        {
            this.educationDetails.push({
                education:"",
                schoolOrCollegeName:"",
                boardUniversityName:"",
                cgpa_percentage:"Percentage",
                branchOrStreamOrFieldName:'',
                cgpa_percentageValue:"",
                yearOfPassing:null,
                edId:'',
                scId:'',
                bouId:'',
                bsfId:'',
            })
        },
        removeEducationRow(index)
        {
            this.educationDetails.splice(index,1)
        },

        addNewRow(){
            //add field
            this.fieldOfInterests.push({
                field:null,
                course:null,
                courseOptions:[{ value: null, text: 'Select' }]
            })
        },
        removeRow(index)
        {
            //remove field
            this.fieldOfInterests.splice(index, 1)
        },
        getIntakeNo(cb)
        {
            var self = this
            db
            .collection(dbCollections.USERS)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                var newIntakeNo=0
                if(!querySnapshot.empty)
                {
                    querySnapshot.forEach(doc=>{
                        if(!doc.data().isDeleted && doc.data().role=="student")
                        {
                            var uniqueId = doc.data().uniqueId
                            if(uniqueId>=newIntakeNo)
                            {
                                newIntakeNo = parseInt(uniqueId)+1
                            }
                            var zeroes = 6-newIntakeNo.toString().length

                            for (let i = 0; i < zeroes; i++) {
                                newIntakeNo='0'+newIntakeNo
                            }
                        }
                    })
                    if(newIntakeNo==0)
                    {
                        newIntakeNo='000001'
                    }
                }
                else
                {
                    newIntakeNo='000001'
                }
                cb(newIntakeNo)
            })
        },
        saveStudentData() {
            var self = this
            var currentUsers = JSON.parse(localStorage.getItem('currentUser'));

            // var fieldOfInterests = []
            // self.fieldOfInterests.forEach((data,index)=>{
            //     if(data.field!=null)
            //     {
            //         fieldOfInterests.push(data)
            //     }
            // })
            // self.fieldOfInterests = fieldOfInterests
            self.checkValidPhoneNumber(self.mobileNumber);
            
            self.$refs.personalInfoForm.validate().then(success => {
                if (success && !self.checkEmail && self.validNumberOrNot) {
                    self.name = self.firstName + ' ' + self.lastName;
                    self.$axios.post(dbCollections.APIURL+'createNewStudent',{email:self.email,name:self.name})
                    .then((resp)=>{
                        if(resp.data.status==200)
                        {
                            self.getIntakeNo(uniqueId=>{
                                // eslint-disable-next-line
                                // console.log("DAta",self.imageFile,self.name,self.intake,self.yearOfPassing,self.email,self.mobileNumber,self.date,self.gender,self.addressLine1,self.addressLine2,self.country,self.state,self.city,self.pincode,self.collegeName,self.branch,self.cgpa_percentage,self.yearOfPassing,self.overall,self.germanLanguage,self.fieldOfInterests)
                                // self.$router.push({name:'student-inquiry'})
                                if(self.imageFile.length!=0)
                                {
                                   let fieldofCourse = []
                                    if(self.fieldOfInterests.length > 0){
                                    self.fieldOfInterests.forEach(elements=>{
                                        if(elements && elements.field !== null){                                            
                                        fieldofCourse.push(
                                            {
                                            'course':{fieldId:elements.field.id,id:elements.field.id,name:elements.course},
                                            'field':{id:elements.field.id,name:elements.field.name}
                                            }
                                        )
                                        }
                                    })
                                    }  
                                    self.uploadingImage = true
                                    uploadImageFile(self.folderName,self.imageFile[0].name,self.imageFile[0],res=>{
                                        db
                                            .collection(dbCollections.USERS)
                                            .doc(resp.data.data.uid)
                                            .set({
                                                'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                                'docFiles':[],
                                                'imageUrl':res.imageUrl,
                                                'imageName':res.imageName,
                                                'name':self.name,
                                                'firstName':self.firstName,
                                                'lastName':self.lastName,

                                                'intake':self.intake,
                                                'year':self.year,
                                                
                                                'email':self.email,
                                                'mobileNumber':self.mobileNumber,
                                                'dob':self.dob,
                                                'gender':self.gender,
                                                'addressLine1':self.addressLine1,
                                                'addressLine2':self.addressLine2,
                                                'country':self.country,
                                                'state':self.state,
                                                'city':self.city,
                                                'pincode':self.pincode,

                                                'areaOfInterest':self.areaOfInterest,

                                                // 'collegeName':self.collegeName,
                                                // 'branch':self.branch,
                                                // 'cgpa_percentage':self.cgpa_percentage,
                                                // 'cgpa_percentageValue':self.cgpa_percentageValue,
                                                // 'yearOfPassing':self.yearOfPassing,
                                                // 'payments':self.payments,

                                                'educationDetails':self.educationDetails,
                                                'language':self.language,
                                                'germanLanguage':self.germanLanguage,
                                                'exams':self.exams,

                                                // 'fieldOfInterests':fieldofCourse,
                                                'role':"student",
                                                'representative':self.representative||'',
                                                'documentModule':self.documentModule,
                                                'status':{status:'Application Pending',variant:'warning'},
                                                'uniqueId':`${uniqueId}`,
                                                'dateOfEnrollment':'date',
                                                'isDeleted':false,
                                                'id':resp.data.data.uid,
                                                'isActive':true,
                                                'countryPhoneCode': self.selectedCountry
                                            })
                                            .then(()=>{
                                                let count = 0;
                                                const studentUpdateFun = (ele) => {
                                                    if(self.educationDetails.length <= count) {
                                                        self.uploadingImage = false
                                                        this.$toast({
                                                            component: ToastificationContent,
                                                            position: 'top-right',
                                                            props: {
                                                                title: `user data add successfully`,
                                                                icon: 'CoffeeIcon',
                                                                variant: 'success',
                                                            },
                                                        })
                                                        setTimeout(()=>{
                                                            self.$router.push({name:'student-mgmt'})
                                                            self.$root.$emit('stopSpinner')
                                                        },3500)
                                                        return;
                                                    } else {
                                                        updateFiledCollectionWithStudentID(ele,resp.data.data.uid,1).then((resp)=>{
                                                            count++;
                                                            studentUpdateFun(self.educationDetails[count])
                                                        }).catch((err)=>{
                                                            count++;
                                                            studentUpdateFun(self.educationDetails[count])
                                                        })
                                                    }
                                                }
                                                studentUpdateFun(self.educationDetails[count])
                                                let notificationObj = {
                                                    title: `Student Created`,
                                                    type: 'studentCreated',
                                                    userId: resp.data.data.uid,
                                                    currentUser:firebase.auth().currentUser.uid
                                                }
                                                if(self.representative) {
                                                    notificationObj.message = `Your profile has been created by ${currentUsers.fullName} under ${self.representative.name}`;
                                                } else {
                                                    notificationObj.message = `Your profile has been created by ${currentUsers.fullName}`;
                                                }
                                                this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                                }).catch((error)=>{
                                                    console.error(error);
                                                })
                                            })
                                            .catch(error=>{
                                                self.$root.$emit('stopSpinner')
                                                console.log(error)
                                            })
                                    })
                                }
                                else
                                {
                                    let fieldofCourse = []
                                    if(self.fieldOfInterests.length > 0){
                                    self.fieldOfInterests.forEach(elements=>{
                                      if(elements && elements.field !== null){ 
                                        fieldofCourse.push(
                                            {
                                            'course':{fieldId:elements.field.id,id:elements.field.id,name:elements.course},
                                            'field':{id:elements.field.id,name:elements.field.name}
                                            }
                                        )
                                      } 
                                    })
                                    }  
                                    db
                                        .collection(dbCollections.USERS)
                                        .doc(resp.data.data.uid)
                                        .set({
                                            'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                            'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                            'docFiles':[],
                                            'imageUrl':'',
                                            'imageName':'',
                                            'name':self.name,
                                            'firstName':self.firstName,
                                            'lastName':self.lastName,

                                            'intake':self.intake,
                                            'year':self.year,

                                            'email':self.email,
                                            'mobileNumber':self.mobileNumber,
                                            'dob':self.dob,
                                            'gender':self.gender,
                                            'addressLine1':self.addressLine1,
                                            'addressLine2':self.addressLine2,
                                            'country':self.country,
                                            'state':self.state,
                                            'city':self.city,
                                            'pincode':self.pincode,

                                            'areaOfInterest':self.areaOfInterest,
                                            
                                            // 'collegeName':self.collegeName,
                                            // 'branch':self.branch,
                                            // 'cgpa_percentage':self.cgpa_percentage,
                                            // 'cgpa_percentageValue':self.cgpa_percentageValue,
                                            // 'yearOfPassing':self.yearOfPassing,
                                            // 'overall':self.overall,
                                            // 'payments':self.payments,

                                            'educationDetails':self.educationDetails,
                                            'language':self.language,
                                            'germanLanguage':self.germanLanguage,
                                            'exams':self.exams,

                                            // 'fieldOfInterests':fieldofCourse,
                                            'role':"student",
                                            'representative':self.representative||'',
                                            'documentModule':self.documentModule,
                                            'status':{status:'Application Pending',variant:'warning'},
                                            'uniqueId':`${uniqueId}`,
                                            'dateOfEnrollment':'date',
                                            
                                            'isDeleted':false,
                                            'id':resp.data.data.uid,
                                            'isActive':true,
                                            'countryPhoneCode': self.selectedCountry
                                        })
                                        .then(()=>{
                                            let count = 0;
                                            const studentUpdateFun = (ele) => {
                                                if(self.educationDetails.length <= count) {
                                                    this.$toast({
                                                        component: ToastificationContent,
                                                        position: 'top-right',
                                                        props: {
                                                            title: `user data add successfully`,
                                                            icon: 'CoffeeIcon',
                                                            variant: 'success',
                                                        },
                                                    })
                                                    setTimeout(()=>{
                                                        self.$router.push({name:'student-mgmt'})
                                                        self.$root.$emit('stopSpinner')
                                                    },3500)
                                                    return;
                                                } else {
                                                    updateFiledCollectionWithStudentID(ele,resp.data.data.uid,1).then((resp)=>{
                                                        count++;
                                                        studentUpdateFun(self.educationDetails[count])
                                                    }).catch((err)=>{
                                                        count++;
                                                        studentUpdateFun(self.educationDetails[count])
                                                    })
                                                }
                                            }
                                            studentUpdateFun(self.educationDetails[count])
                                            let notificationObj = {
                                                title: `Student Created`,
                                                type: 'studentCreated',
                                                userId: resp.data.data.uid,
                                                currentUser:firebase.auth().currentUser.uid
                                            }
                                            if(self.representative) {
                                                notificationObj.message = `Your profile has been created by ${currentUsers.fullName} under ${self.representative.name}`;
                                            } else {
                                                notificationObj.message = `Your profile has been created by ${currentUsers.fullName}`;
                                            }
                                            this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                            }).catch((error)=>{
                                                console.error(error);
                                            })
                                        })
                                        .catch(error=>{
                                            self.$root.$emit('stopSpinner')
                                            console.log(error)
                                        })
                                }
                            })
                        }
                        else
                        {
                            console.log("Error",resp.data.message)
                            self.$root.$emit('stopSpinner')
                        }
                    })
                    .catch(error=>{
                        console.log(error)
                        self.$root.$emit('stopSpinner')
                    })
                }
                else
                {
                    console.log("Success",success)
                    self.$root.$emit('stopSpinner')
                }
            })
        },
        changeBirthDate(){                    
            var self = this

            var maxDate = self.getMaxDate().split('-')
            var selectedDate = self.dob.split('-')

            if(selectedDate[0]>maxDate[0])//If Year not valid
            {
                self.dob = '';
                return;
            }
            else if(selectedDate[0]==maxDate[0])
            {
                if(selectedDate[1]>maxDate[1])//If month not valid
                {
                    self.dob = '';
                    return;
                }
                else
                {
                    if(selectedDate[1]==maxDate[1] && selectedDate[2]>maxDate[2])//If date not valid
                    {
                        self.dob = '';
                        return;
                    }
                }
            }
        },
        getOptionsOfEducation() {
            var self = this
            try {     
                this.isSpinner = true;
                if(this.snapshotListener !== null) {
                    this.snapshotListener();
                }
                self.snapshotListener = db.collection(dbCollections.DEGREEFIELD).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList.splice(index, 1);
                            }
                        }
                    })
                    self.documentList.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.educationOptions = [{value:'', text:"select"}];
                    self.$nextTick(()=>{
                        self.documentList = self.documentList.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList.forEach((ele)=>{
                            self.educationOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        },
        getOptionsOfSchoolColleges() {
            var self = this
            try {     
                this.isSpinner = true;
                if(self.snapshotListener1 !== null) {
                    self.snapshotListener1();
                }
                self.snapshotListener1 = db.collection(dbCollections.COLLEGESFIEDLS).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList1 = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList1.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList1[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList1.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList1.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList1[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList1.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList1.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList1.splice(index, 1);
                            }
                        }
                    })
                    self.documentList1.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.schoolOrCollegeNameOptions = [{value:'', text:"select",disabled: true}]
                    self.$nextTick(()=>{
                        self.documentList1 = self.documentList1.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList1.forEach((ele)=>{
                            self.schoolOrCollegeNameOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.schoolOrCollegeNameOptions.push({
                            value:'others',
                            text:'Others'
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        },
        getOptionsOfUniversity() {
            var self = this;
            try {     
                if(self.snapshotListener2 !== null) {
                    self.snapshotListener2();
                }
                this.isSpinner = true;
                self.snapshotListener2 = db.collection(dbCollections.UNIVERSITIESFIELDS).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList2 = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList2.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList2[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList2.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList2.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList2[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList2.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList2.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList2.splice(index, 1);
                            }
                        }
                    })
                    self.documentList2.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.boardUniversityNameOptions = [{value:'', text:"select",disabled: true}]
                    self.$nextTick(()=>{
                        self.documentList2 = self.documentList2.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList2.forEach((ele)=>{
                            self.boardUniversityNameOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.boardUniversityNameOptions.push({
                            value:'others',
                            text:'Others'
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        },
        getOptionsOfBranchFiels() {
            var self = this;
            try {     
                if(self.snapshotListener3 !== null) {
                    self.snapshotListener3();
                }
                this.isSpinner = true;
                self.snapshotListener3 = db.collection(dbCollections.BRANCHSTREAMFIELDS).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList3 = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList3.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList3[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList3.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList3.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList3[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList3.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList3.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList3.splice(index, 1);
                            }
                        }
                    })
                    self.documentList3.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.branchFieldOptions = [{value:'', text:"select",disabled: true}]
                    self.$nextTick(()=>{
                        self.documentList3 = self.documentList3.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList3.forEach((ele)=>{
                            self.branchFieldOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.branchFieldOptions.push({
                            value:'others',
                            text:'Others'
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        }
    },
    computed:{
        checkEmail()
        {
            var found = false
            var self = this

            if(self.email!='')
            {
                self.allEmails.forEach(mail=>{
                    if(mail==self.email)
                    {
                        found = true
                    }
                })
            }

            return found
        },
    }
}
</script>
